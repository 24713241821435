import 'babel-polyfill';
import axios from 'axios';
import Vue from 'vue/dist/vue.js';

import SmoothScroll from './smooth-scroll.polyfills.min.js';

var blog_post = require('./blog-post.js');

var lang = new Vue({
    el: '#button-language',
    data: {
        languages: {
            cs: { location: "/cs/", label: "CZ", lang: "cs" },
            en: { location: "/", label: "EN", lang: "en" }
        },
        originLanguage: "en"
    },
    computed: {
        currentLanguage: function () {
            if (window.location.pathname !== "/" && window.location.protocol !== 'file:') {
                return this.getPathnameLang();
            } else {
                return this.isForced() ? this.originLanguage : this.getNavigatorLang();
            }
        },
        nextLanguage: function () {
            return this.currentLanguage === this.originLanguage ? this.languages.cs : this.languages.en;
        }
    },
    methods: {
        getPathnameLang: function () {
            return window.location.pathname.toString().substring(1, 3);
        },
        getNavigatorLang: function () {
            return (navigator.language || navigator.userLanguage).split("-")[0];
        },
        getLanguageUrl: function (lang) {
            var url = window.location.origin + lang.location;
            if (this.getNavigatorLang() !== lang.lang) {
                url += "?force=true";
            }
            return url;
        },
        isForced: function() {
            return window.location.search.toString().includes("force=true");
        }
    },
    mounted() {
        if (!this.isForced() && this.currentLanguage !== this.originLanguage && this.currentLanguage !== this.getPathnameLang()) {
            window.location.replace(this.getLanguageUrl(this.languages[this.currentLanguage]));
        }
    }
});

var blog = new Vue({
    el: '#blog',
    data: {
        posts: []
    },
    template:
        '<div v-if="posts.length" class="last-news">'
            + '<h5><img class="icon" src="/src/images/news_icon.svg" /> Blog</h5>'
            + '<blog-post v-for="(p, i) in posts" :post="p" ref="post" :key="`post-${i}`" />'
        + '</div>',
    computed: {
        blogUrl: function() {
            return "https://datlab.eu/blog";
        },
        apiUrl: function() {
            return this.blogUrl + "/ghost/api/v3/content";
        },
        apiClientId: function() {
            return "test";
        },
        apiClientSecret: function() {
            return "b89dbfd64842478a86d35ee33a";
        }
    },
    created() {
        this.getLastPosts();
    },
    methods: {
        getApiRequestUrl: function(endpoint, params) {
            var query = [];
            // params["client_id"] = this.apiClientId;
            // params["client_secret"] = this.apiClientSecret;
            params["key"] = this.apiClientSecret;
            Object.keys(params).forEach(i => query.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i])));
            return this.apiUrl+ "/" + endpoint + "/?" + query.join("&");
        },
        getLastPosts:  function () {
            var vm = this;

            var url = this.getApiRequestUrl("posts", {limit: 3, fields: "title,url"});

            axios.get(url)
                .then(function (response) {
                    vm.posts = [];
                    vm.posts.push.apply(vm.posts, response.data.posts);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
});

var scroll_mailto = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    clip: true,
    easing: 'easeInOutCubic'
});

var scroll_down = new SmoothScroll('#button-down', {
    speed: 1000,
    clip: true,
    easing: 'easeInOutCubic',
    offset: 33
});

function showMenu() {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (!classes.includes("show")) {
        classes.push("show")
        content.className = classes.join(" ")
    }
}

function hideMenu() {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (classes.includes("show")) {
        classes.splice(classes.indexOf("show"), 1)
        content.className = classes.join(" ")
    }
}

// menu toggler events
document.getElementById("menu-toggler").addEventListener("touchstart", evt => {
    var content = document.getElementById("menu-content")
    var classes = content.className ? content.className.split(" ") : []

    if (classes.includes("show")) {
        classes.splice(classes.indexOf("show"), 1)
    } else {
        classes.push("show")
    }

    content.className = classes.join(" ")
})

document.getElementById("menu-toggler").addEventListener("mouseover", showMenu)
document.getElementById("menu-toggler").addEventListener("mouseleave", hideMenu)

document.getElementById("menu-content").addEventListener("mouseover", showMenu)
document.getElementById("menu-content").addEventListener("mouseleave", hideMenu)



