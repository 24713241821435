import Vue from 'vue/dist/vue.js'

Vue.component('blog-post', {
    props: ["post"],
    template:
        '<a class="blog-post" v-bind:href="post.url" target="_blank">'
            + '<h6>{{ post.title }}</h6>'
            + '<img class="arrow" src="/src/images/double_arrow.svg" />'
        + '</a>'
});




